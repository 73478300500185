import { IUserRatingComponentProps } from '../../../lib/types/components';
import { dynamicCssSize } from "../../../lib/utils/constants/PagesConstants";
import CustomImageComponent from '../CustomImage/CustomImageComponent';


const UserRatingComponentV2 = (props: IUserRatingComponentProps) => {

    const {customerReviewData,winzoWinnerCompProps} = props;

    const RatingProgressBar = (props: any) => {
        const {color, value} = props;

        return (
            <>
                <style jsx>{`
                  .ratingProgress {
                    height: ${dynamicCssSize(12, 5)};
                    width: 100%;
                    background-color: rgba(0, 0, 0, 0.25);
                    transition: .3s;
                    transform: skewX(-20deg);
                  }

                  .ratingProgressBar {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    background-color: ${color};
                    position: relative;
                  }
                  
                `}
                </style>
                <div className={"ratingProgress"}>
                    <div
                        className={"ratingProgressBar"}
                        style={{width: `${value}%`}}
                    >
                    </div>
                </div>
            </>

        );
    };

    return (
        customerReviewData && Object.keys(customerReviewData).length ?
            <>
                <style jsx>{`
                  .parentRatingContainer {
                    background-color: #ebebeb;
                    font-family: var(--font-primary);
                    padding: ${dynamicCssSize(20, 0)} 0px 4.6vw;
                  }

                  .styleContainer {
                    padding: ${dynamicCssSize(20, 0)} 0px;
                  }

                  .heading {
                    display: block;
                    text-align: center;
                    margin: 0 ${dynamicCssSize(114,30)} ${dynamicCssSize(32,10)} ${dynamicCssSize(115,30)};
                    object-fit: contain;
                    font-size: ${dynamicCssSize(60,20)};
                    font-weight: 900;
                    font-stretch: normal;
                    font-style: italic;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #35255a;
                    font-family: var(--font-primary);
                  }

                  .totalRating {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border-right: 0.7px solid #372e2e;
                  }

                  .ratingContainer {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    margin-bottom: ${dynamicCssSize(50, 20)};
                  }

                  .ratingHeading {
                    background-image: linear-gradient(0deg, rgba(98, 37, 138, 1) 0%, rgba(179, 9, 127, 1) 100%);
                    font-size: ${dynamicCssSize(208, 40)};
                    font-weight: 900;
                    line-height: 0.9;
                    font-style: italic;
                    text-align: left;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    padding: 0px ${dynamicCssSize(40, 20)};
                  }

                  .ratingDesc {
                    font-size: ${dynamicCssSize(20, 10)};
                    font-weight: 600;
                    font-style: italic;
                    text-transform: uppercase;
                  }

                  .ratingStarsContainer {
                    display: flex;
                    margin: 10px 0px 10px;
                  }

                  .ratingStars {
                    position: relative;
                    margin: 0px ${dynamicCssSize(10, 2)} 0px 0px;
                    width: ${dynamicCssSize(64, 14)};
                    height: ${dynamicCssSize(64, 14)};
                  }

                  .ratingInformation {
                    padding: 0px ${dynamicCssSize(30, 15)};
                  }

                  .ratingInfoRow {
                    display: flex;
                    align-items: center;
                    margin: 10px 0px;
                  }

                  .ratingNumber {
                    font-size: ${dynamicCssSize(22, 12)};
                    font-weight: 600;
                    font-style: italic;
                  }

                  .ratingInfoStarsContainer {
                    display: flex;
                    margin: 0px ${dynamicCssSize(25, 5)} 0px ${dynamicCssSize(20, 5)};
                  }

                  .ratingInfoStars {
                    position: relative;
                    width: ${dynamicCssSize(32, 11)};
                    height: ${dynamicCssSize(32, 11)};
                    margin: 0px 2px;
                  }

                  .ratingBar {
                    display: flex;
                    align-items: center;
                  }

                  .progressBar {
                    width: ${dynamicCssSize(250, 80)};
                  }

                  .percentageContainer {
                    padding: 0px ${dynamicCssSize(10, 5)};
                    font-size: ${dynamicCssSize(16, 10)};
                    font-weight: 600;
                    font-style: italic;
                    text-align: center;
                    color: #000;
                  }

                  @media screen and (max-width: 767px) {
                    .heading {
                      margin-bottom: 15px;
                    }

                    .ratingInfoRow {
                      margin: 5px 0px;
                    }

                    .ratingDesc {
                      font-style: normal;
                    }

                    .totalRating {
                      border-right: 1px solid #ababab;
                    }
                  }

                `}
                </style>
                <div className={"container-fluid parentRatingContainer"}>
                    <div className="styleContainer container" id="id_why_winzo">
                        <h2 className="heading">{customerReviewData.heading}</h2>
                        <div className="ratingContainer">
                            <div className={"totalRating"}>
                                <div className={"ratingHeading"}>{customerReviewData.text1}</div>
                                <div className={"ratingStarsContainer"}>{
                                    [...Array(5)].map((x, i)=>{
                                        let imgSrc = "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/";
                                        imgSrc += i === 4 ? "halfStarV2.svg" : "starV2.svg";
                                        return(
                                            <div className={"ratingStars"} key={"rating_" + i}>
                                                <CustomImageComponent
                                                    src={imgSrc}
                                                    layout={'fill'}
                                                    alt={"star"}
                                                />
                                            </div>
                                        )
                                    })
                                }</div>
                                <div className={"ratingDesc"}>{customerReviewData.text2}</div>
                            </div>
                            <div className={"ratingInformation"}>
                                {
                                    [...Array(5)].map((x, i)=>{
                                        const currentRating = 5-i;
                                        let percent;
                                        let color;
                                        switch (currentRating){
                                            case 5:
                                                percent=79;
                                                color="#39b061"
                                                break;
                                            case 4:
                                                percent=15;
                                                color="#92e042"
                                                break;
                                            case 3:
                                                percent=4;
                                                color="#f4d249"
                                                break;
                                            case 2:
                                                percent=1;
                                                color="#fbb851"
                                                break;
                                            case 1:
                                                percent=1;
                                                color="#f17a54"
                                                break;

                                        }
                                        return(
                                            <div className={"ratingInfoRow"} key={"rating_info_" + i}>
                                                <div className={"ratingNumber"}>
                                                    {currentRating}
                                                </div>
                                                <div className={"ratingInfoStarsContainer"}>
                                                    {
                                                        [...Array(5)].map((y, j)=>{
                                                            let imgSrc = "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/";
                                                            imgSrc += j < currentRating ? "starV2.svg" : "emptyStarV2.svg";
                                                            return(
                                                                <div className={"ratingInfoStars"} key={"rating_info_stars_" + j}>
                                                                    <CustomImageComponent
                                                                        src={imgSrc}
                                                                        layout={'fill'}
                                                                        alt={"star"}
                                                                    />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <div className={"ratingBar"}>
                                                    <div className={"progressBar"}>
                                                        <RatingProgressBar
                                                            percentage={percent}
                                                            color={color}
                                                            value={percent}
                                                        />
                                                    </div>
                                                    <div className={"percentageContainer"}>
                                                        {`${percent}%`}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
            :
            <></>
    )
}

export default UserRatingComponentV2;